/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { dataTable } from "variables/general";
import { GETALLUSERAPI } from "services/ApiRequest";
import { SUSPENDREINSTATEUSERAPI } from "services/ApiRequest";
import { DELETEUSERAPI } from "services/ApiRequest";
import Swal from "sweetalert2";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";


const { SearchBar } = Search;

function ReactBSTables() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [userData, setUserData] = useState([]);
  const [dateRage, setDateRange] = useState({ startDate: "", endDate: "" });
  const [search, setSearch] = useState('');
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  const pagination = paginationFactory({
    page: page,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Showing{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  });
  

  const getAllUser= async(date) =>{
    if((date.startDate !== '' && date.endDate !== '') && ((date.startDate).length < 12 && (date.endDate).length < 12 )){
      GETALLUSERAPI({ dates: date, search: search })
      .then((e)=>{
        const newData = e.data.data.payload;
        setUserData([...newData])
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      document.title= 'Dashboard'
    }else if(search !== ''){
      GETALLUSERAPI({search:search})
        .then((e)=>{
          const newData = e.data.data.payload;
          setUserData([...newData])
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        document.title= 'Users'
    }else{
      GETALLUSERAPI()
        .then((e)=>{
          const newData = e.data.data.payload;
          setUserData([...newData])
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        document.title= 'Users'
    }
  }
  useEffect(() => {
    getAllUser(dateRage);
  }, [dateRage, search]);

  const headingStyle = {
    background: "rgb(222 226 230 / 51%)",
    color: "#5c646d",
    fontWeight: "bold",
    textAlign: "center",
  }

  const handleDelete = async(id, isDeleted) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: isDeleted==="true" ? 'Yes, Delete it!' : 'Yes, Revert Delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        DELETEUSERAPI({}, id, isDeleted)
        .then((e)=>{
          getAllUser(dateRage)
        })
        Swal.fire(
          isDeleted==="true" ? 'Deleted!' : 'Reverted',
          isDeleted==="true" ? 'User has been Deleted successfully!' : 'User has been Reverted successfully!',
          'success'
        )
      }
    })
  }

  const handleSuspend = async(id, isSuspended) => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: isSuspended==="true" ? 'Yes, Suspend it!' : 'Yes, Re-Instate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        SUSPENDREINSTATEUSERAPI({}, id, isSuspended)
        .then((e)=>{
          getAllUser(dateRage)
        })
        Swal.fire(
          isSuspended==="true" ? 'Suspended!' : 'Re-Instate',
          isSuspended==="true" ? 'User has been Suspended successfully!' : 'User has been Re-Instate successfully!',
          'success'
        )
      }
    })
  }

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
  };

  return (
    <>
      {alert}
      <SimpleHeader name="List of All Users" parentName="All Users" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h2 className="mb-0">All Users</h2>
                <p className="text-sm mb-0">
                  This is a List of All Users.
                </p>
              </CardHeader>
              <ToolkitProvider
                data={userData}
                keyField="_id"
                columns={[
                  {
                    dataField: "index",
                    text: "Sr.",
                    sort: false,
                    formatter: (cell, row, rowIndex) => {
                      return rowIndex + 1;
                    },
                    headerStyle:{
                      background: "rgb(222 226 230 / 51%)",
                      color: "#5c646d",
                      fontWeight: "bold",
                    },
                  },
                  {
                    dataField: "firstName",
                    text: "User Profile",
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <div style={{textAlign:'center', margin:'auto'}}>
                          <div style={{ width: '200px', margin:'auto', display:'flex'}}>
                            <img src={row.avatar} alt="" width="30px" height="30px" style={{ margin: '0 2rem', borderRadius: '50px'}} />
                              <span style={{ margin: "auto", alignItems:'center'}}>{`${row.firstName} ${row.lastName}`}</span>
                          </div>
                        </div>
                      )
                    },
                    headerStyle: headingStyle,
                  },
                  {
                    dataField: "createdAt",
                    text: "Created At",
                    sort: true,
                    formatter: (cell) => {
                    const date = new Date(cell);
                      return date.toLocaleDateString();
                    },
                    headerStyle: headingStyle,
                    style:{
                      textAlign: 'center',
                    }
                  },
                  {
                    dataField: "actions",
                    text: "Actions",
                    sort: false,
                    formatter: (cell, row) => {
                      return (
                        <>
                          {!row.isDeleted ? (
                            row.isSuspended ? (
                            <button
                              onClick={() => handleSuspend(row._id, "false")} // Handle button click for Re-Instate
                              className="btn btn-success btn-sm"
                            >
                              Re-Instate
                            </button>
                          ) : (
                            <button
                              onClick={() => handleSuspend(row._id, "true")} // Handle button click for Suspend
                              className="btn btn-warning btn-sm"
                            >
                              Suspend
                            </button>
                          )): ""}
                          {row.isDeleted ? (
                            <button
                              onClick={() => handleDelete(row._id, "false")} // Handle button click for Deleted
                              className="btn btn btn-outline-danger btn-sm"
                            >
                              Deleted
                            </button>
                          ) : (
                            <button
                              onClick={() => handleDelete(row._id, "true")} // Handle button click for Delete
                              className="btn btn-danger btn-sm"
                            >
                              Delete
                            </button>
                          )}
                        </>
                      );
                    },
                    headerStyle: headingStyle,
                    style:{
                      textAlign: 'center'
                    }
                  },
                ]}
                search
                
              >
                {(props) => (
                  <div className="p-4 table-responsive">
                    {/* <Container fluid style={{marginBottom:'10px', padding:'0px !important'}}> */}
                      <Row style={{padding:'0'}}>
                        <Col xs={12} className="px-3 pb-2">
                          <input
                            className="form-control-sm float-right"
                            placeholder="Search User"
                            value={search}
                            onChange={handleSearchChange}
                          />
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter p-0 pb-1 float-left"
                          >
                            <DateRangePicker
                              dateRange={dateRage}
                              setDateRange={setDateRange} 
                              page="list"
                            />
                          </div>
                        </Col>
                        <Col>
                          
                        </Col>
                      </Row>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                      rowStyle={(row, rowIndex) => {
                        return {
                          backgroundColor: row.isDeleted ? '#80808033' : '',
                        };
                      }}
                      rowClasses="hovered-row"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ReactBSTables;
