import axios from "axios";
import { LOGIN, BASE_URL_API, DASHBOARD_COUNTS, DELETE_USER, REPORTED_USERS, GET_ALL_USERS, SUS_REIN_USER } from './ApiConstant';


const API_TIMEOUT = 10000;
const TIMEOUT = 9000;

const instance = axios.create({
  baseURL: BASE_URL_API,
  timeout: API_TIMEOUT,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers",
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});


// Auth Header With TOken For Axios Request
export const GetAuthHeader = () => {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authtoken")}`,
      },
    };
  };

export const LOGINAPI = async (obj) => {
    return await axios.post(`${BASE_URL_API}${LOGIN}`, obj);
};

export const GETALLREPORTEDUSERAPI = async (obj) => {
    return await axios.post(`${BASE_URL_API}${REPORTED_USERS}`, obj, GetAuthHeader());
}

export const DELETEUSERAPI = async (obj, id, isDeleted) => {
    const userId = id+"/";
    return await axios.post(`${BASE_URL_API}${DELETE_USER}${userId}${isDeleted}`, obj, GetAuthHeader());
}

export const DASHBOARDCOUNTAPI = async (obj) => {
    return await axios.post(`${BASE_URL_API}${DASHBOARD_COUNTS}`, obj, GetAuthHeader());
}

export const GETALLUSERAPI = async (obj) => {
    return await axios.post(`${BASE_URL_API}${GET_ALL_USERS}`, obj, GetAuthHeader());
}

export const SUSPENDREINSTATEUSERAPI = async (obj, id, isSuspended) => {
    const userId = id+"/";
    return await axios.post(`${BASE_URL_API}${SUS_REIN_USER}${userId}${isSuspended}`, obj, GetAuthHeader());
}