/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import { useEffect } from "react";
import { DASHBOARDCOUNTAPI } from "services/ApiRequest";
import { useState } from "react";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [dataEventCounts, setDataEventCounts] = useState([]);
  const [dataUserCounts, setDataUserCounts] = useState([]);
  const [dataClasses, setDataClasses] = useState(true);
  const [countData, setCountData] = useState({
    userCount:0,
    eventCount:0,
    userCountDateRange:0,
    eventCountDateRange:0
  })
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  })
  const [range, setRange] = useState(dateRange);

  const getCounting = (date) => {
    if((date.startDate !== '' && date.endDate !== '') && ((date.startDate).length < 12 && (date.endDate).length < 12 )){
      DASHBOARDCOUNTAPI(date)
      .then((e)=>{
        const newData = {
          userCount:e.data.data.totalUsers,
          eventCount:e.data.data.totalEvents,
          userCountDateRange:e.data.data.totalUsersCreatedInDateRange,
          eventCountDateRange:e.data.data.totalEventsCreatedInDateRange
        }
        setDataEventCounts(e.data.data.dataEventCounts);
        setDataUserCounts(e.data.data.dataUserCounts);
        setCountData(newData);
        setDataClasses(!dataClasses)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      document.title= 'Dashboard'
    }   
  }

  function generateDatesBetween(startDate, endDate) {
    const dates = [];
    const currentDate = new Date(startDate);
    // console.log("current",currentDate)
    const end = new Date(endDate);
    currentDate.setDate(currentDate.getDate() + 1)
    end.setDate(end.getDate() + 1)
  
    while (currentDate <= end) {
      dates.push(currentDate.toISOString().split('T')[0]);
      // console.log("dates",dates)
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates;
  }

  const [eventDataClass, setEventDataClass] = useState({});
  const [userDataClass, setUserDataClass] = useState({});
  useEffect(() => {
    getCounting(range);
  }, [range]);

  useEffect(()=>{
    const generatedDates = range.startDate === '' || range.endDate === '' ? generateDatesBetween(dateRange.startDate, dateRange.endDate) : generateDatesBetween(range.startDate, range.endDate);
    console.log(range);
    setEventDataClass({
      data:{
        datasets:[
          {
            data:[
              ...dataEventCounts
            ]
      }
        ],
        labels:[
          ...generatedDates,
        ]
      }
    })
    setUserDataClass({
      data:{
        datasets:[
          {
            data:[
              ...dataUserCounts
            ]
      }
        ],
        labels:[
          ...generatedDates,
        ]
      }
    })
  }, [dataClasses])
  return (
    <>
      <CardsHeader name="Dashboard" parentName="Dashboards" data={countData} setRange={setRange} />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">New Users</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={userDataClass.data}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col xl="12">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 mb-0">New Events</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={eventDataClass.data}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
