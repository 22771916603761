

function ReportModal({ repotedBy, reportedTo, date, desc }){
    return(
        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Report</h5>
                </div>
                <div className="modal-body">
                    <div className="reportDetails">
                        <pre>Reported By : <span> {repotedBy}</span></pre>
                        <pre>Reported To : <span> {reportedTo}</span></pre>
                    </div>
                    <pre>Reason</pre>
                    <div style={{width:"100%", padding:"20px", border:"1px solid blue", borderRadius:'10px', fontSize:'14px'}}>
                        {desc}
                    </div>
                    <p>This report is created at {date}</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ReportModal;