/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import { useCookies } from "react-cookie";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthFooter from "components/Footers/AuthFooter";
import { LOGINAPI } from "services/ApiRequest";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [credentials, setcredentials] = React.useState({
    email: "",
    password: "",
  });
  const [focused, setFocused] = useState({
    email: false,
    password: false,
  });
  const [name, setName] = useState("");
  const [pwd, setPwd] = useState("");
  const [cookies, setCookie] = useCookies(["user"]);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');


  useEffect(() => {
    if (cookies.Username && cookies.Password) {
      setcredentials({
        email: cookies.Username,
        password: cookies.Password,
      });
    }
    if (localStorage.getItem("authtoken")) {
      console.log(localStorage.getItem("authtoken"), "after Login");
      Swal.fire({
        icon: "success",
        title: "Already logged in",
      }).then(() => {
        navigate("/admin/dashboard");
      });
    } else {
      navigate("/login");
    }
    document.title = "Login";
  }, []);

  const handleFocus = (e) => {
    setFocused({ ...focused, [e.target.name]: true });
    setEmailError('');
    setPasswordError('');
  };

  const onChange = (e) => {
    setcredentials({ ...credentials, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      setPwd(e.target.value);
    }
    if (e.target.name === "email") {
      setName(e.target.value);
    }
  };

  const setCookies = () => {
    setCookie("Username", credentials.email, { path: "/" });
    setCookie("Password", credentials.password, { path: "/" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!credentials.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailError('Please provide a valid email.');
      isValid = false;
    }

    if (!credentials.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/)) {
      setPasswordError('Password must contain at least 8 characters, including at least one letter, one number, and one special character without space.');
      isValid = false;
    }

    if (isValid) {
      LOGINAPI(credentials)
      .then((e) => {
        localStorage.setItem("authtoken", e.data.data.accessToken);
        Swal.fire({
          icon: "success",
          title: e.data.message || "Successfully Login",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          navigate("/admin/dashboard");
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: error.response.message,
          text:
            error.response.data.message,
        });
      });
    }
  };

  return (
    <>
    <div className="bg-login">
      <AuthHeader
        title="Welcome!"
        lead="RoseMary Admin Portal"
      />
      <Container className="mt--9 pb-5 bg-login">
        <Row className="justify-content-center">
          <Col md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <large>Login with Credentials</large>
                </div>
                <Form role="form" onSubmit={handleSubmit} className="needs-validation was-validated" noValidate>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        id="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={onChange}
                        value={credentials.email}
                        name="email"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        minLength={3}
                        onBlur={handleFocus}
                        focused={focused.email.toString()}
                        required
                      />
                      <div className="invalid-feedback ml-2">
                      {emailError}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={onChange}
                        value={credentials.password}
                        name="password"
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
                        onBlur={handleFocus}
                        focused={focused.password.toString()}
                        required
                        minLength={8}
                      />
                      <div className="invalid-feedback ml-2">
                      {passwordError}
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <div>
                    <input
                      id=" customCheckLogin"
                      type="checkbox"
                      className="checkmark mr-2"
                      onClick={() => setCookies()}
                    />
                    <label
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted"> Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Login
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </div>
      <AuthFooter />
    </>
  );
}

export default Login;
