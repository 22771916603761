import { useEffect } from "react";
import Swal from "sweetalert2";

const { useState } = require("react");
const { useNavigate } = require("react-router-dom");



function Logout(){
    const navigate = useNavigate()

    useEffect(() => {
        window.history.back();
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to Logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
          }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
              Swal.fire(
                'Logged Out!',
              );
              navigate('/login');
            }
          })
      }, []);
}

export default Logout;