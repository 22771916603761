
// export const BASE_URL = "http://localhost:8002";
export const BASE_URL = "https://rosemary.traversetec.dev";
export const BASE_URL_API = `${BASE_URL}/api/admin`;



// for Login
export const LOGIN = '/login-admin'

export const DASHBOARD_COUNTS = '/dashboard';
export const GET_ALL_USERS = '/get-all-users';
export const SUS_REIN_USER =  '/suspend-reinstate/';
export const REPORTED_USERS = '/get-reported-users';
export const DELETE_USER =  '/delete/';