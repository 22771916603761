/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Logout from "views/pages/login/Logout";
import ReactBSTables from "views/pages/lists/UserList.js";
import Report from "views/pages/lists/ReportList";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/all-users",
    name: "All Users",
    icon: "ni ni-circle-08 text-orange",
    component: <ReactBSTables />,
    layout: "/admin",
  },
  // {
  //   path: "/all-reported-users",
  //   name: "Reported Users",
  //   icon: "ni ni-ruler-pencil text-info",
  //   component: <Report />,
  //   layout: "/admin",
  // },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-button-power text-green",
    component: <Logout />,
    layout: "/admin",
  },
];

export default routes;
