import React from 'react'
import { useEffect, useRef, useState } from 'react'
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Footer from 'react-multi-date-picker/plugins/range_picker_footer'
/**
 * return range of dates, it should accept function to receive range
 * 
 * state={ startTime: '', endTime: '' }
 * @param getDate 
 * @returns 
 */
const DateRangePicker = ({dateRange,setDateRange,page}) => {
  

  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null);
  const [dates, setDates] = useState([])

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)

    if(dateRange.startYear?.length<1){
      setDates([])
    }
  }, [dateRange]);

  const setValue=(val)=>{
    setDateRange({startDate:val[0]?.year+'-'+val[0]?.month+'-'+val[0]?.day,endDate:val[1]?.year+'-'+val[1]?.month+'-'+val[1]?.day})
  }

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {

    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  };

  let style = {width: '155px',height:'30px', border: 'none',  fontSize:'0.8rem',color:'#8898aa',fontFamily:'sans-serif', marginLeft: "5px", padding:'0 3px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
  if(page==='list'){
    style = {width: '155px',height:'30px',  fontSize:'0.8rem',color:'#8898aa',border: '2px solid #dee1e2', fontFamily:'sans-serif', marginLeft: "5px", padding:'0 3px', background: '#eef0f2'}
  }

  return (
    <>
      <DatePicker value={dates} className='date_range' maxDate={new Date()} onChange={setValue} style={style} placeholder='Select Date Range'  range plugins={[
    <DatePanel />,
    <Footer position="bottom" />
  ]}/>
    </>
  )
}

export default DateRangePicker;

